
import Vue from 'vue';
import TopIcon from '@/components/TopIcon.vue'
import Title from '@/components/Title.vue';
import Tag from '@/components/Tag.vue';

export default Vue.extend({
  components: {
    TopIcon,
    Title,
    Tag,
  },
  data: () => ({
    information: [
      {
        title: '好きなもの',
        content: [
          'ラーメン', 'Twitter', 'お酒', '麻雀', 'ガジェット', 'ThinkPad'
        ],
      },
      {
        title: '趣味',
        content: [
          'サイクリング',
          'beatmaniaIIDX',
          'Re:ステージ!プリズムステップ',
          'スマートホーム',
        ],
      },
      {
        title: '興味のある分野',
        content: [
          'FrontEnd',
          'Competitive Programming',
          'Deep Learning',
          'Mobile Application',
          'CTF',
        ],
      },
      {
        title: '言語・フレームワーク',
        content: [
          'TypeScript',
          'JavaScript',
          'Vue.js',
          'Firebase',
          'Docker',
          'Ruby',
          'Python',
          'TensorFlow',
          'C',
          'C++',
        ],
      },
    ],
    topIcon: 'twitter_icon.png',
  }),
});
