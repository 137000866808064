
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Work extends Vue {
	@Prop({ default: ''})
	title!: string

	@Prop()
	url?: string

	@Prop()
	release?: string
	
	@Prop()
	imgUrl?: string
	
	@Prop()
	description?: string

	public loadImg() {
		return require(`@/assets/${this.imgUrl}`)
	}
}
