
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class TopIcon extends Vue {
	@Prop()
	imgPath?: string

	public loadImg() {
		return require(`@/assets/${this.imgPath}`)
	}
}
