
import { Component, Vue } from 'vue-property-decorator'
import Hamburger from '@/components/Hamburger.vue'
import Menu from '@/components/Menu.vue'
@Component({
	components: {
		Hamburger,
		Menu,
	},
})
export default class HamburgerMenu extends Vue {
	isActive: boolean = false

	inverse() {
		this.isActive = !this.isActive
	}
}
