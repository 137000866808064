
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WorkCard extends Vue {
	@Prop()
	title!: string
	@Prop()
	url?: string
	@Prop()
	release?: string
	@Prop()
	imgUrl?: string
	@Prop()
	description?: string

	public loadImg() {
		if(this.imgUrl !== '') {
			return require(`@/assets/${this.imgUrl}`)
		} else {
			return require(`@/assets/noimage.png`)
		}
	}
}
