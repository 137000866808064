
import Vue from 'vue';

import Career from '@/components/Career.vue';

export default Vue.extend({
  components: {
    Career,
  },
  data: () => ({
    careers: [
      {
        name: "株式会社日本システム技研",
        tags: ["Python", "Django", "BackEnd"],
        description: "社内日報システムの開発",
        start: new Date(Date.parse("2017-09-04")),
        end: new Date(Date.parse("2017-09-15")),
      },
      {
        name: "イーストフィールズ株式会社",
        tags: ["JavaScript", "Vue.js", "TypeScript", "Firebase", "Algolia", "FrontEnd"],
        description: "フリーコンサルへの案件紹介サービスのフロントエンド開発",
        start: new Date(Date.parse("2020-06-15")),
        end: null,
      },
      {
        name: "株式会社いい生活",
        tags: ["JavaScript", "Vue.js", "TypeScript", "FrontEnd"],
        description: "サマーインターン2020, 社内向けAPIを用いたチーム開発",
        start: new Date(Date.parse("2020-08-31")),
        end: new Date(Date.parse("2020-09-04")),
      },
      {
        name: "合同会社DMM.com",
        tags: ["DevOps", "FrontEnd", "Kotlin", "React.js", "Django", "TypeScript"],
        description: "DMM GUILD 2020, 50を超えるDMMのサービスから提示された技術的な課題(クエスト)を選択し解決、複数事業issue賞を受賞",
        start: new Date(Date.parse("2020-09-07")),
        end: new Date(Date.parse("2020-09-18")),
      },
    ],
  }),
});
