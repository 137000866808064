
import Vue, { PropType } from 'vue';

import Tag from '@/components/Tag.vue';

type Career = {
  name: string;
  tags?: string[];
  description: string;
  start: Date;
  end: Date | null;
}

export default Vue.extend({
  components: {
    Tag,
  },
  props: {
    career: { type: Object as PropType<Career> },
  },
  methods: {
    dateToStr(date: Date | null): string {
      if (date !== null) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}年${month}月${day}日`;
      } else {
        return '現在';
      }
    },
  },
  computed: {
    startStr(): string {
      return this.dateToStr(this.career.start);
    },
    endStr(): string {
      return this.dateToStr(this.career.end);
    },
  },
});
