
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Content extends Vue {
	@Prop()
	public message?: string

	show(){
		alert(this.message)
	}

}
