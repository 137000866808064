
import { Component, Vue } from 'vue-property-decorator';
import myheader from '@/components/header.vue'
import Info from '@/components/Info.vue'
import Links from '@/components/Links.vue'
import Contact from '@/components/Contact.vue'
import Works from '@/components/Works.vue'
import IDs from '@/components/IDs.vue'

@Component({
  components: {
    myheader,
		Info,
		Links,
		Contact,
		Works,
		IDs,
  },
})
export default class App extends Vue {
	items: object[] = [
		{ message: 'Foo' },
		{ message: 'Bar' },
	]
	public msg: string = 'Hello, World!'
	
	clear() {
		this.msg = ''
	}

	push(){
		this.items.push({ message: 'New' })
	}
}
