
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LinkCard extends Vue {
	@Prop()
	path?: string
	@Prop()
	title?: string
	@Prop()
	id?: string
	@Prop({ default: 'none' })
	color?: string
	@Prop({ default: '' })
	fav!: string | undefined

	// prefixと分離
	icon: string[] = (this.fav !== undefined) ? this.fav.split(' ') : []
}
