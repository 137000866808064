
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Link extends Vue {
	@Prop()
	path?: string

	@Prop()
	title?: string

	@Prop()
	id?: string

	@Prop()
	color?: string
}
