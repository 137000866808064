
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
@Component
export default class Menu extends Vue {
	@Prop()
	public isActive: boolean = false

	links: object[] = [
		{ path: '/info', title: 'Home' },
		{ path: '/links', title: 'Links' },
		{ path: '/works', title: 'Works' },
		// { path: '/IDs', title: 'IDs' },
    { path: '/careers', title: 'Careers' },
		{ path: '/contact', title: 'Contact' },
	]

	@Emit()
	public click(){}
}
