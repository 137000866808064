
import { Component, Vue } from 'vue-property-decorator'
import Work from '@/components/Work.vue'
import WorkCard from '@/components/WorkCard.vue'

@Component({
	components: {
		Work,
		WorkCard,
	},
})
export default class Works extends Vue {
	title:string = 'QR-generator'
	works: object[] = [
		{ 
			title: 'QR-generator',
			url: 'https://github.com/shikachii/QR-generator',
			release: 'https://github.com/shikachii/QR-generator/releases/tag/v0.1.0',
			imgUrl: 'QR-generator.png',
			description: 'WebページのURLをQRコードとして表示するChrome拡張'
		},
		{
			title: 'shikachii.net',
			url: 'https://github.com/shikachii/shikachii.net',
			release: 'https://shikachii.net',
			imgUrl: '',
			description: 'このWebサイト'
		},
	]	
}
